<template>
    <div class="spotlight widget" @click="onCardClick">
        <h1 class="title">
            {{ title }}
        </h1>
        <div class="widget-container" :class="{ 'sub-title-top' : subTitleTop }">
            <figure>
                <img :src="image">
            </figure>
            <h2 v-if="subTitle != ''" class="sub-title" :style="`background-color: ${color}`">
                {{ subTitle }}
            </h2>
        </div>
    </div>
</template>

<script>
export default {
    name: "SpotlightWidget",
    props: {
        image: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        },
        subTitle: {
            type: String,
            default: ""
        },
        subTitleTop: {
            type: Boolean,
            default: false
        },
        category: {
            type: String,
            default: ""
        },
        entity: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        onCardClick() {
            switch (this.category) {
                case "Memos":
                    return this.$router.push({
                        name: "memod-reader",
                        params: {
                            writer: this.entity.writer.displayname,
                            slug: this.entity.slug,
                            id: Number(this.entity.id),
                            partNumber: 1
                        }
                    });
                case "Topics":
                    return this.$router.push({
                        name: "web-app-topics",
                        params: { topicSlug: this.entity.slug }
                    });
                case "Users":
                    return this.$router.push({
                        name: "web-app-profile",
                        params: {
                            displayName: this.entity.displayname
                        }
                    });
                case "Boards":
                    return this.$router.push({
                        name: "web-app-board-view",
                        params: {
                            displayName: this.entity.user.displayname,
                            id: Number(this.entity.id)
                        }
                    });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.spotlight {
    width: 350px;
    // height: 440px;
    cursor: pointer;

    .title {
        font-weight: 600;
        font-size: 26px;
        margin-bottom: 15px;
        margin-top: 10px;
    }

    .widget-container {
        height: 440px;
        background-color: #1C1C1E;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;

        figure {
            display: flex;
            justify-content: center;
            margin-bottom: 0;
            overflow: hidden;
            flex: 1;
            order: 20;

            img {
                object-fit: cover;
                width: 100%;
            }
        }

        .sub-title {
            order: 30;
            padding: 12px;
            font-size: 22px;
            font-weight: 400;
            // background: linear-gradient(90deg, #02AAB0 0%, lighten(#02AAB0, 20%) 100%);
        }

        &.sub-title-top {
            .sub-title {
                order: 10;
            }
        }
    }
}
</style>
